"use client";
import { useState } from "react";
import { Input } from "@digitale-kumpel/client-common/src/components/forms/Input";
import { Hero } from "@digitale-kumpel/client-common/src/components/sections/Hero/Hero";
import { Enum_Componentsectionshero_Variant } from "@digitale-kumpel/client-common/src/graphql/generated/graphql";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "@digitale-kumpel/client-common/src/hooks/useYupValidationResolver";
import { FormItem } from "@digitale-kumpel/client-common/src/components/forms/FormItem";
import { Button } from "@digitale-kumpel/client-common/src/components/actions/Button/Button";
import { Alerts } from "@digitale-kumpel/client-common/src/components/feedback/Alerts/Alerts";
import { Severity } from "@digitale-kumpel/client-common/src/interfaces/notification";
import { useMutation } from "urql";
import { LoginDocument } from "@/graphql/generated/graphql";
import { useRouter, useSearchParams } from "next/navigation";
import { signIn } from "next-auth/react";
import { Container } from "@digitale-kumpel/client-common/src/components/layout/Container";
import { TextLink } from "@digitale-kumpel/client-common/src/components/navigation/TextLink";
import { routes } from "@/config/routes";

const schema = yup.object({
  identifier: yup.string().required("Benutzername ist ein Pflichtfeld"),
  password: yup.string().required("Passwort ist ein Pflichtfeld"),
});

type IFormInputs = yup.InferType<typeof schema>;

const signInErrors = {
  CredentialsSignin: "Kein Nutzer mit diesen Daten gefunden",
  default: "Der Login ist fehlgeschlagen",
};

export default function Login() {
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get("callbackUrl");
  const { push } = useRouter();
  const resolver = useYupValidationResolver(schema);
  const [requestErrors, setRequestErrors] = useState<{ label: string }[]>([]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormInputs>({ resolver });

  const [loginResult, login] = useMutation(LoginDocument);

  async function handleRegister(data: IFormInputs, event) {
    event.preventDefault();
    setRequestErrors([]);
    const result = await signIn("credentials", {
      identifier: data.identifier,
      password: data.password,
      redirect: false,
    });
    if (!result?.error) {
      push(callbackUrl || "/my/courses");
    } else {
      setRequestErrors([
        { label: signInErrors[result?.error] ?? signInErrors.default },
      ]);
    }
  }

  return (
    <>
      <Hero
        id="1"
        variant={Enum_Componentsectionshero_Variant.Small}
        title={"Melde dich mit deinem Account an"}
      />
      <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-sm">
        {requestErrors?.length > 0 && (
          <Alerts
            errors={requestErrors}
            severity={Severity.ERROR}
            title={"Ein Fehler ist aufgetreten"}
          />
        )}
        <form onSubmit={handleSubmit(handleRegister)}>
          <FormItem id="identifier" label="Benutzername">
            <Input
              id="identifier"
              {...register("identifier")}
              error={errors.identifier?.message}
            />
          </FormItem>
          <FormItem id="password" label="Passwort">
            <Input
              id="password"
              type="password"
              {...register("password")}
              error={errors.password?.message}
            />
          </FormItem>
          <div className="mt-2 flex gap-2 items-center">
            <Button
              isLoading={loginResult.fetching}
              fullWidth={false}
              type="submit"
            >
              Einloggen
            </Button>
            <TextLink href={routes.REGISTER} className="block">
              oder registrieren
            </TextLink>
          </div>
          <TextLink href={routes.FORGOT_PASSWORD} className="mt-2 block">
            Passwort vergessen?
          </TextLink>
        </form>
      </div>
    </>
  );
}
